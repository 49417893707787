<template>
  <div class="content md flex-box vertical temp-detail" v-if="info">
    <div class="temp-detail-ctx">
      <div class="temp-detail-rule">
        <div class="set-info-item">
          <div class="set-info-label">可见范围</div>
          <div class="set-info-ctx">
            <select-book :edit="edit" multiple type="book" v-model="original_visible_ids"></select-book>
          </div>
        </div>
        <div class="set-info-item">
          <div class="set-info-label">管理员</div>
          <div class="set-info-ctx">
            <select-book :edit="edit" multiple type="book" v-model="original_admin_ids"></select-book>
          </div>
        </div>
        <template v-if="!(report || enroll)">
          <div class="set-info-item">
            <div class="set-info-label">审批流程</div>
            <div class="set-info-ctx">
              <a-button @click="setProcess">设置</a-button>
              <div class="temp-process">
                <process-list :nodes="nodes" :extra-temp="info.extraTemp"></process-list>
              </div>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">修改权限</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="otherConfig.approveUnchangeable" :disabled="!edit">提交申请时，申请人不可修改固定审批人</a-checkbox>
              <br/>
              <a-checkbox v-model="otherConfig.ccUnDelete" :disabled="!edit">提交申请时，申请人不可删除固定抄送人</a-checkbox>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">消息通知</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="otherConfig.receiveMessage" :disabled="!edit">成员可收到消息通知</a-checkbox>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">撤销权限</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="otherConfig.allowRevocation" :disabled="!edit">申请通过后允许撤销<span class="set-info-tip">审批通过后，经审批人和办理人同意可撤销申请</span></a-checkbox>
            </div>
          </div>
          <div class="set-info-item" v-if="includesCoupon">
            <div class="set-info-label">核销权限</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="otherConfig.allowSelfCheck" :disabled="!edit">允许申请人主动核销{{info.name}}券</a-checkbox>
            </div>
          </div>
          <div class="set-info-item">
            <div class="set-info-label">后台申请</div>
            <div class="set-info-ctx">
              <a-checkbox v-model="otherConfig.canAdminApply" :disabled="!edit">管理后台可提交此应用申请</a-checkbox>
            </div>
          </div>
        </template>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="save">保存</a-button>
      </template>
      <a-button type="primary" @click="editTemp" v-else>编辑</a-button>
    </a-space>
  </div>
</template>

<script>
import {getTempName} from "@/common/constant/template";
import processList from "@/components/process-list";
import {clone} from "@/common/js/tool";

export default {
  name: "TemplateSettingComp",
  components: {
    processList
  },
  data() {
    return {
      edit: false,
      doorList: [],
      original_visible_ids: [],
      original_admin_ids: [],
      nodes: [],
      otherConfig: {}
    }
  },
  computed: {
    info() {
      return this.$store.getters.temp;
    },
    // 是否生成券 用餐 上香 礼品会生成券
    includesCoupon() {
      return this.info && [7, 8, 9].includes(this.info.type);
    },
    report() {
      let info = this.info;
      return (info && info.is_oa == 1 && info.type == 3) || (info.is_oa == 5 && [29, 30].includes(info.type));
    },
    enroll() {
      // 报名审核
      let info = this.info;
      return info && info.is_oa == 5 && info.oa_type == 8 && info.type == 42;
    },
    is_volunteer() {
      // 志工
      return this.info.is_oa == 5;
    },
    router_prefix() {
      return this.is_volunteer ? 'Volunteer' : '';
    },
  },
  created() {
    this.dealInfo(this.info)
  },
  watch: {
    info(val) {
      this.dealInfo(val)
    }
  },
  methods: {
    getTempName,
    setProcess() {
      if(this.edit) {
        this.$confirm({
          title: '提示',
          content: '您处于编辑状态，离开此页您的改动将不会保存，确定离开此页吗？',
          okText: '离开此页',
          cancelText: '留在此页',
          onOk: () => {
            this.goEditProcess();
          }
        })
      } else {
        this.goEditProcess();
      }
    },
    goEditProcess() {
      this.$store.commit("setDetail", {type: 'temp', obj: this.info});
      this.$router.push({name: `${this.router_prefix}Process`, params: {id: this.info.id}});
    },
    cancel() {
      this.edit = false;
    },
    editTemp() {
      this.edit = true;
    },
    save() {
      let {original_visible_ids, original_admin_ids} = this;
      if(!original_visible_ids || original_visible_ids.length <= 0) {
        this.$message.error("请设置可见范围");
        return;
      }
      if(!original_admin_ids || original_admin_ids.length <= 0) {
        this.$message.error("请设置管理员");
        return;
      }
      let visible_ids = {
        uids: [],
        dept: [],
        tag: []
      };
      original_visible_ids.forEach(item => {
        if(item.type == "department") {
          visible_ids.dept.push(item.id);
        } else if(item.type == 'user') {
          visible_ids.uids.push(item.id);
        } else if(item.type == 'tag') {
          visible_ids.tag.push(item.id);
        }
      });
      let admin_ids = {
        uids: [],
        dept: [],
        tag: []
      }
      original_admin_ids.forEach(item => {
        if(item.type == "department") {
          admin_ids.dept.push(item.id);
        } else if(item.type == 'user') {
          admin_ids.uids.push(item.id);
        } else if(item.type == 'tag') {
          admin_ids.tag.push(item.id);
        }
      });
      this.updateTemp({
        original_visible_ids: JSON.stringify(original_visible_ids),
        visible_ids,
        original_admin_ids: JSON.stringify(original_admin_ids),
        admin_ids,
        other_config: JSON.stringify(this.otherConfig)
      })
    },
    updateTemp(data) {
      let url = `/admin/apply-temp/${this.info.id}`;
      this.$axios({
        url,
        method: "PATCH",
        data
      }).then(() => {
        let temp = clone(this.info);
        temp = Object.assign(temp, data);
        this.$store.commit("setTemp", temp);
        this.$message.success("更新成功");
        this.edit = false;
      });
    },
    getDoorMan() {
      let url = '/doorman';
      this.$axios(url).then(res => {
        this.doorList = res.data;
      })
    },
    dealInfo(info) {
      if(info) {
        if(info.original_visible_ids) {
          this.original_visible_ids = JSON.parse(info.original_visible_ids);
        }
        if(info.original_admin_ids) {
          this.original_admin_ids = JSON.parse(info.original_admin_ids);
        }
        if(info.flow) {
          this.nodes = JSON.parse(info.flow);
        } else {
          this.nodes = [];
        }
        let otherConfig = {};
        if(info.other_config) {
          try {
            otherConfig = JSON.parse(info.other_config);
          } catch (e) {
            console.error(e);
          }
        }
        if(otherConfig.receiveMessage === undefined) {
          otherConfig.receiveMessage = true;
        }
        if(otherConfig.approveUnchangeable === undefined) {
          otherConfig.approveUnchangeable = true;
        }
        if(otherConfig.ccUnDelete === undefined) {
          otherConfig.ccUnDelete = true;
        }
        this.otherConfig = otherConfig;
        // 车辆报备加载门岗人员
        if(info.type == 13) {
          this.getDoorMan();
        }
      }
    },
  }
}
</script>

<style lang="less">
.temp-detail {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.temp-detail-tabs {
  display: flex;
  border-bottom: var(--border);
}
.temp-tab-item {
  position: relative;
  padding: 12px 16px;
  &:not(:first-child) {
    margin-left: 24px;
  }
  cursor: pointer;
  &.active {
    color: @primary-color;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px;
      border-radius: 2px;
      background-color: @primary-color;
    }
  }
}
.temp-detail-ctx {
  flex: 1;
  padding-top: 16px;
  min-height: 0;
}
.set-temp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: var(--border);
  .temp-icon-img {
    width: 32px;
    height: 32px;
  }
}
.set-info-ctx {
  min-width: 0;
}
.set-info-tip {
  margin-left: 0.5em;
  color: @text-color-secondary;
}
.temp-set {
  height: 100%;
}
.temp-set-form {
  height: 100%;
}
.temp-process {
  display: flex;
  margin-top: 16px;
  padding: 12px 0;
  min-width: 0;
  max-height: 480px;
  overflow: scroll;
  background-color: @background-color-base;
}
.temp-detail-rule {
  padding-bottom: 16px;
  height: 100%;
  overflow: auto;
}
</style>
